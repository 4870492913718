<template>
    <div class="page-content white-bg">
        <NewsGuide :typeId="typeId" />
    </div>
</template>
<script>
export default {
    name: "mediation-center",
    data() {
        return {
            typeId: "10000059",
        };
    },
};
</script>
<style lang="less" scoped>
</style>
